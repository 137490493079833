import React, { useState,useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "./jobs.css";
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import { Skeleton } from 'antd';
import {useLocation} from "react-router-dom";
import { Empty } from 'antd';
import { Dropdown,Modal } from 'react-bootstrap';
import { Tooltip, Button } from 'antd';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


function Reportdetail() {
  const [Loader,setLoader] = useState(false)
  const search = useLocation().search;
  const name = new URLSearchParams(search).get('id');

  const apphandleClose = () => setapproveshow(false);
  const [approveshow, setapproveshow] = useState(false);
  const [contactid,setcontactid] = useState()
  const apphandleShow = (val) => {
    setapproveshow(true)
    setcontactid(val)
  }


  const [alluser,setalluser] = useState([])
  useEffect(() => {
    fetch('https://server.hirechoices.com/getAllUser')
    .then(res => res.json())
    .then((response) => {
      setalluser(response.user)
    })
  }
  ,[]);


  useEffect(() => {
    setLoader(true)
    fetch(`https://server.hirechoices.com/getAlljobs/report/${name}`)
    .then(res => res.json())
    .then((response) => {
      setalljobs(response.resume)
    })
    .then(response=>{setLoader(false)})
    .catch(()=>{setLoader(false)})
  }
  ,[]);

  const [alljobs,setalljobs] = useState([])
  return (
    <>
      <Modal show={approveshow} onHide={apphandleClose}>
        <Modal.Body>
        {alljobs&&alljobs.filter(val => val.id == contactid).map((val,ind) => (
          <>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                First Name
              </label>
              <input type="text" readOnly defaultValue={alluser&&alluser.length>0?alluser[ind].first_name:""} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Last Name
              </label>
              <input type="text" readOnly defaultValue={alluser&&alluser.length>0?alluser[ind].last_name:""} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Reason
              </label>
              <input type="text" readOnly defaultValue={val.reason} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Detail
              </label>
              <textarea type="text" rows="10" readOnly defaultValue={val.details} className="form-control"/>
            </div>
          </>
        ))}
        </Modal.Body>
       
      </Modal>

      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
        <div className="d-flex align-items-start w-100">
        <div className="w-100 bg-white p-4">
        <div className="recr-table w-100 d-flex align-items-center justify-content-between">
            <h3>Report Detail</h3>
          </div>
          <div className="user-list">
          {Loader == false ?
            <>
            <table style={{display:"inline-table"}} class="table table-hover table-responsive">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Reason</th>
                <th scope="col">Details</th>
                <th scope="col">Created at</th>
                <th scope="col">View</th>
              </tr>
            </thead>
            
            <tbody>
              <>
              {alljobs&&alljobs.length>0?alljobs.map((val,ind) => 
                <>
                <tr>
                  <td>{alluser&&alluser.length>0?alluser.filter(data=>data.id==val.user_id)[0].first_name:""}</td>
                  <td>{alluser&&alluser.length>0?alluser.filter(data=>data.id==val.user_id)[0].last_name:""}</td>
                  <td>{val.reason}</td>
                  <td style={{width:"300px"}}>
                      {val.details}
                  </td>
                  <td><ReactTimeAgo date={val.created_at} locale="en-US"/></td>
                  <td>
                    <Tooltip placement="topLeft" title={"View"}>
                    <button style={{background:"transparent",border:"none"}} onClick={()=>{apphandleShow(val.id)}}>
                      <i class="fa fa-eye mr-2 text-success" aria-hidden="true"></i>
                    </button>
                    </Tooltip>
                </td>
                </tr>
                </>
              ):<p className="py-2">No Detail Available</p>}
              </>
            </tbody>
          </table>
          </>
            :<Skeleton active />}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reportdetail;


// code from scoopsolutions