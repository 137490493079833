import React, { useState, useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "./users.css";
import avatar from "../../assests/avatar.png";
import TimeAgo from 'javascript-time-ago';
import { Link } from "react-router-dom";

import ReactTimeAgo from 'react-time-ago';
import { Dropdown,Modal } from 'react-bootstrap';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import { Tooltip, Button } from 'antd';
import InputMask from 'react-input-mask';
import Loader from "../../components/fullpageloader/fullPageLoader"
import Loader2 from "../../components/fullpageloader/fullPageLoader2"
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { Skeleton } from 'antd';
import { Drawer, Radio, Space } from 'antd';
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)




function Employeer() {
  const [loaders,setloaders]=useState(false)
  const [lilloaders,setlilloaders]=useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Updateid, setUpdateid] = useState(false);
  const [user,setuser] = useState([])


  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [position_title, setposition_title] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");


 


  const first_name_type1 = (values) => {
    setfirst_name(values)
  };
  const last_name_type1 = (values) => {
    setlast_name(values)
  };
  const position_title_type1 = (values) => {
    setposition_title(values)
  };
  const company_name_type1 = (values) => {
    setcompany_name(values)
  };
  const city_type1 = (values) => {
    setcity(values)
  };
  const state_type1 = (values) => {
    setstate(values)
  };
  const zip_code_type1 = (values) => {
    setzip_code(values)
  };
  const phone_type1 = (values) => {
    setphone(values)
  };
  const email_type1 = (values) => {
    setemail(values)
  };

  const handleShow = (val) => {
    setUpdateid(val)
    setShow(true)
    fetch(`https://server.hirechoices.com/user/getby/${val}`)
    .then(res => res.json())
    .then(response => {
      setfirst_name(response.job[0].first_name)
      setlast_name(response.job[0].last_name)
      setposition_title(response.job[0].position_title)
      setcity(response.job[0].city)
      setcompany_name(response.job[0].company_name)
      setstate(response.job[0].state)
      setzip_code(response.job[0].zip_code)
      setphone(response.job[0].phone)
      setemail(response.job[0].email)
      setlatitude(response.job[0].latitude)
      setlongitude(response.job[0].longitude)
    })
  }

  useEffect(()=>{
      setlilloaders(true)
      fetch("https://server.hirechoices.com/getAllUser")
      .then(res => res.json())
      .then((response) => {
        setuser(response.user)
        setlilloaders(false)
      })
  },[])


  const [appid,setappid]=useState("")
  const [appbinary,setappbinary]=useState("")
  const apphandleClose = () => setapproveshow(false);
  const apphandleShow = (id,appr) => {
    setappid(id)
    setappbinary(appr)
    setapproveshow(true)
  }

  const approvehandler = () =>{
    setloaders(true)
    const update = {status:appbinary,userid:appid}
    fetch("https://server.hirechoices.com/userStatusRegister", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(update)
    })
    .then((res)=>{return res.json()}).then(response=>{
      window.location="/employer"
      setloaders(true)
    })
    .catch(()=>{
      setloaders(false)
    })
  }



  const submit=()=>{
    setloaders(true)
    const update = {first_name:first_name,last_name:last_name,position_title:position_title,company_name:company_name,city:city,state:state,zip_code:zip_code,phone:phone,email:email,latitude:corr1.lat!==""&&corr1.lat!==null&&corr1.lat!==undefined?corr1.lat:latitude,longitude:corr1.lng!==""&&corr1.lng!==null&&corr1.lng!==undefined?corr1.lng:longitude,id:Updateid}
    fetch("https://server.hirechoices.com/user/update", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) => {
      window.location="/employer"
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );
    })
  }
  const [approveshow, setapproveshow] = useState(false);
  const [creatshow, setcreatshow] = useState(false);
  const createhandleClose = () => setcreatshow(false);
  const createhandleShow = () => setcreatshow(true);




  const [userdec,setuserdec] = useState("1")
  const [cfirst_name, setcfirst_name] = useState("");
  const [clast_name, setclast_name] = useState("");
  const [cposition_title, setcposition_title] = useState("");
  const [ccompany_name, setccompany_name] = useState("");
  const [ccity,setccity] = useState("")
  const [cstate, setcstate] = useState("");
  const [czip_code, setczip_code] = useState("");
  const [source, setsource] = useState("");
  const [cphone, setcphone] = useState("");
  const [cemail, setcemail] = useState("");
  const [clatitude, setclatitude] = useState("");
  const [clongitude, setclongitude] = useState("");
  const [cpassword, setcpassword] = useState("");
const[error,seterror]=useState("")




  const csubmit=()=>{
    setloaders(true)
    const update = {first_name:cfirst_name,last_name:clast_name,position_title:cposition_title,company_name:ccompany_name,city:ccity.split(",")[0],state:cstate,zip_code:czip_code,phone:cphone,email:cemail,source:source,password:cpassword,role:userdec,latitude:corr.lat,longitude:corr.lng}
    fetch("https://server.hirechoices.com/register", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) => {
      if(res.message!=="The email you have entered is already registered with us. Please use one of our sign in options to access your account."){
        window.location="/employer"
      }
    else{
seterror("The email you have entered is already registered with us. Please use one of our sign in options to access your account.")
    }
      setTimeout(
        () => setloaders(false), 
        3000
      );
    })
  }

  const[corr,setcorr]=useState({
    lat:null,
    lng:null
  })
  const handleSelect=async(value)=>{
const result=await geocodeByAddress(value)
const ll=await getLatLng(result[0])
setccity(value)
setcorr(ll)
  }


  const[corr1,setcorr1]=useState({
    lat1:null,
    lng1:null
  })
  const handleSelect1=async(value)=>{
const result=await geocodeByAddress(value)
const ll=await getLatLng(result[0])
setcity(value)
setcorr1(ll)
  }




  const [showdelete, setShowdelete] = useState(false);
  const [DeleteId, setDeleteId] = useState();
  const handleDelete = () => setShowdelete(false);
  const DeleteModal = (val)=>{
    setShowdelete(true)
    setDeleteId(val)
  }
  const DeleteThisUser=()=>{
    setloaders(true)
    const Delete = {id:DeleteId}
    fetch("https://server.hirechoices.com/user/delete", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(Delete)
    })
    .then(response => response.json())
    .then((res) => {
        window.location="/employer"
        setTimeout(
          () => setloaders(false), 
          3000
        );
    })
  }
  return (
    <>
  {!loaders ?
    <>

      <Modal show={creatshow} onHide={createhandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Employer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex flex-column job-update-input-parent">
            <label>
              First Name
            </label>
            <input type="text" defaultValue={cfirst_name} onChange={(e)=>{setcfirst_name(e.target.value)}} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Last Name
              </label>
              <input type="text" defaultValue={clast_name} onChange={(e)=>{setclast_name(e.target.value)}} className="form-control"/>
            </div>
            {userdec == 1?
              <>
              <div className="d-flex flex-column job-update-input-parent">
              <label>
                Position Title
              </label>
              <input type="text" defaultValue={cposition_title} onChange={(e)=>{setcposition_title(e.target.value)}} className="form-control"/>
              </div>
              <div className="d-flex flex-column job-update-input-parent">
                <label>
                  Company Name
                </label>
                <input type="text" defaultValue={ccompany_name} onChange={(e)=>{setccompany_name(e.target.value)}} className="form-control"/>
              </div>
              </>
              :""
            }
            
            <div className="d-flex align-items-center">
                <div className="d-flex flex-column job-update-input-parent">
                  <label>
                  City
                  </label>
                  <PlacesAutocomplete
        value={ccity.split(",")[0]}
        onChange={setccity}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
            id="exampleInputPassword1"
            className="form-control employee-input-email job-search"
              {...getInputProps({
                placeholder: 'city',
                className: 'form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                  {/* <input type="text" defaultValue={ccity} onChange={(e)=>{setccity(e.target.value)}} className="form-control"/> */}
                </div>



                <div className="d-flex flex-column job-update-input-parent">
                  <label>
                  State
                  </label>
                  <select defaultValue={cstate} onChange={(e)=>{setcstate(e.target.value)}} style={{width: '60px !important', height: '40px'}} className="form-control"
                      >
                        <option  selected value="Select">Select</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                    </select>         
                </div>



                <div className="d-flex flex-column job-update-input-parent">
                  <label>
                  Zip Code
                  </label>
                  <input type="text" defaultValue={czip_code} onChange={(e)=>{setczip_code(e.target.value)}} className="form-control"/>
                </div>
            </div>

            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Phone Number
              </label>
              <InputMask mask="(999) 999-9999" defaultValue={cphone} className="form-control employee-input-email job-search w-100" onChange={(e)=>{setcphone(e.target.value)}} placeholder="(xxx) xxx-xxxx" />
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Email
              </label>
              <input defaultValue={cemail} onChange={(e)=>{setcemail(e.target.value)}} type="text" className="form-control"/>
           <p className="text-danger">{error}</p>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Password
              </label>
              <input type="password" defaultValue={cpassword} onChange={(e)=>{setcpassword(e.target.value)}} className="form-control"/>
            </div>
            <Modal.Footer>
          <Button variant="primary" onClick={()=>{csubmit()}}>
            Save
          </Button>
        </Modal.Footer>
        </Modal.Body>
      </Modal>
      <Modal show={approveshow} onHide={apphandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to {appbinary == 0 ?  "Unapprove" : "Approve"} this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{approvehandler()}}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Employer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              First Name
            </label>
            <input type="text" Value={first_name} onChange={(e) => {first_name_type1(e.target.value)}} className="form-control"/>
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Last Name
            </label>
            <input type="text" Value={last_name} onChange={(e) => {last_name_type1(e.target.value)}} className="form-control"/>
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Position Title
            </label>
            <input type="text" Value={position_title} onChange={(e) => {position_title_type1(e.target.value)}} className="form-control"/>
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Company Name
            </label>
            <input type="text" Value={company_name} onChange={(e) => {company_name_type1(e.target.value)}} className="form-control"/>
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              City
            </label>
            <PlacesAutocomplete
        value={city.split(",")[0]}
        // Value={address!==""?address.split(",")[0]:value.job[0].city}
        onChange={setcity}
        onSelect={handleSelect1}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
            id="exampleInputPassword1"
            className="form-control employee-input-email job-search w-25"
              {...getInputProps({
                placeholder: 'city',
                className: 'form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
            {/* <input type="text" Value={city} onChange={(e) => {city_type1(e.target.value)}} className="form-control"/> */}
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            State
            </label>
            <input type="text" Value={state} onChange={(e) => {state_type1(e.target.value)}} className="form-control"/>
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Zip Code
            </label>
            <input type="text" Value={zip_code} onChange={(e) => {zip_code_type1(e.target.value)}} className="form-control"/>
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Phone
            </label>
            <input type="text" Value={phone} onChange={(e) => {phone_type1(e.target.value)}} className="form-control"/>
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Email
            </label>
            <input type="text" Value={email} onChange={(e) => {email_type1(e.target.value)}} className="form-control"/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{submit()}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>



        <Modal show={showdelete} onHide={handleDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDelete}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{DeleteThisUser()}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>







      
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
      <div className="d-flex align-items-start w-100">
        <div className="w-100 bg-white p-4">
        <div className="recr-table w-100 d-flex align-items-center justify-content-between">
            <h3>Employer</h3>
            <Button variant="primary" onClick={createhandleShow}>Add New</Button>
          </div>
          <div className="user-list">
          {lilloaders?
              <Skeleton active avatar/>
              :
              <div class="table-responsive">
            <table class="table table-hover ">
            <thead>
              <tr>
                <th scope="col">Detail</th>
                <th scope="col">Company</th>
                <th scope="col">Approved</th>
                <th scope="col">Verified</th>
                <th scope="col">Last Login</th>
                <th scope="col">Update</th>
              </tr>
            </thead>
            <tbody>
              <>
              {user&&user.length>0?user.filter(val => val.role!== 0).map((val) => 
                val.role == 4?"":
                <>
                <tr>
                <td>
                  <div className="d-flex align-items-center w-auto">
                    <div className="avatar">
                    <img src={avatar} alt="" />
                    </div>
                    <div className="profile-underuser-name">
                      <h5>{val.last_name}, {val.first_name}</h5>
                      <h6>{val.email}</h6>
                    </div>
                  </div>
                </td>
                <td>{val.company_name}</td>
                <td><span onClick={()=>{apphandleShow(val.id,val.approve == 0 ? 1 : 0)}} style={{backgroundColor:val.approve == 0 ? "red":"green",paddingInline:10,paddingBlock:5,borderRadius:5,color:"white",cursor:"pointer"}}>{val.approve == 0 ? "Unapproved":"Approved"}</span></td>
                <td><span style={{backgroundColor:val.verify == 1 ? "green":"red",paddingInline:10,paddingBlock:5,borderRadius:5,color:"white",cursor:"pointer"}}>{val.verify == 1 ? "Verified":"Unverified"}</span></td>
                <td><ReactTimeAgo date={val.last_login} locale="en-US"/></td>
                <td className="user-action">
                  {val.role == 4 ?
                    ""
                    :
                    <>
                    <Tooltip placement="topLeft" title={"Update"}>
                    <button onClick={()=>{handleShow(val.id)}}>
                      <i class="fa fa-pencil mr-2 text-success" aria-hidden="true"></i>
                    </button>
                    </Tooltip>
                     <Tooltip placement="topLeft" title={"Delete"}>
                     <button onClick={()=>{DeleteModal(val.id)}}>
                     <i class="fa fa-trash mr-2 text-danger" aria-hidden="true"></i>
                     </button>
                     </Tooltip>
                     </>
                  }
                 
                </td>
                
                </tr>
                </>
              ):<p className="py-2">No User Available</p>}
              </>
            </tbody>
          </table>
          </div>
        }
              
          </div>
          </div>
        </div>
      </div>
    </>
    :<Loader/>}
    </>
  );
}

export default Employeer;



// code from scoopsolutions