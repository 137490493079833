import React, { useState, useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import { Dropdown,Modal } from 'react-bootstrap';
import "./helpcenter.css";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Tooltip, Button } from 'antd';
import parse from 'html-react-parser';
import Loader from "../../components/fullpageloader/fullPageLoader"

function HelpCenter() {
  const [loaders,setloaders]=useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [question, setquestion] = useState();
  const [answer, setanswer] = useState();
  const [role, setrole] = useState();
  const [id, setid] = useState();

  const [addrole, setaddrole] = useState("0");
  const [addquestion, setaddquestion] = useState();
  const [addanswer, setaddanswer] = useState();



  useEffect(()=>{
    fetch("https://server.hirechoices.com/help/get")
    .then(res => res.json())
    .then((response) => {
      setdescription(response.job)
    })
  },[])


  const edit = (val) =>{
    setShow(true)
    fetch(`https://server.hirechoices.com/help/getBy/${val}`)
    .then(res => res.json())
    .then((response) => {
      setquestion(response.job[0].question)
      setanswer(response.job[0].answer)
      setrole(response.job[0].role)
      setid(response.job[0].id)
    })
  }

  const questionfunc=(val)=>{
    setquestion(val)
  }

  const answerfunc=(val)=>{
    setanswer(val)
  }



  const addhelp=()=>{
    setloaders(true)
    const update = {question:addquestion,answer:addanswer,role:addrole}
    fetch("https://server.hirechoices.com/help/insert", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) => {
      window.location="/helpcenter"
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );
    })

  }






  const submit=(role,id)=>{
    setloaders(true)
    const update = {question:question,answer:answer,role:role,id:id}
    fetch("https://server.hirechoices.com/help/update", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) => {
      window.location="/helpcenter"
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );
    })

  }

  const deletehelp=(id)=>{
    setloaders(true)
    const update = {id:id}
    fetch("https://server.hirechoices.com/help/delete", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) => {
      window.location="/helpcenter"
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );
    })
  }

  const[description,setdescription]=useState([])
  const [deleteshowid, setdeleteshowid] = useState();
  const [deleteshow, setdeleteshow] = useState(false);
  const handledeleteClose = () => setdeleteshow(false);
  const handledeleteShow = (id) =>{
   setdeleteshow(true)
   setdeleteshowid(id)
  }



  const [insert, setinsert] = useState(false);
  const handleinsertClose = () => setinsert(false);
  const handleinsert = () => setinsert(true);
  return (
    <>
{!loaders ?
    <>
      <Modal show={deleteshow} onHide={handledeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure</Modal.Title>
        </Modal.Header>
        <Modal.Body>Stop! Are You Really Want To Delete This</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{deletehelp(deleteshowid)}}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={insert} onHide={handleinsertClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Help Q&A</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
              <div>
              <label className="m-0">Question</label>
              <input onChange={(e)=>{setaddquestion(e.target.value)}} type="text" className="form-control"/>
              </div>
              
              <div className="mt-3">
                <label  className="m-0">Answer</label>
                <CKEditor
                    editor={ ClassicEditor }
                    ProtectedTags = 'html|head|body'
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setaddanswer(data)
                    } }
                />
                {}
              </div>
              <div>
              <label className="mt-3 ">Role</label>
              <select onChange={(e)=>{setaddrole(e.target.value)}} className="form-control" id="">
                <option value="0">Candidate</option>
                <option value="1">Employer</option>
              </select>
              </div>
            </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{addhelp()}}>
            Add
          </Button>
        </Modal.Footer>

      </Modal>
      <Modal className="update-job-modal" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Help Q&A</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
            <div>
              <label className="m-0">Question</label>
              <input defaultValue={question} onChange={(e)=>{questionfunc(e.target.value)}} type="text" className="form-control"/>
              </div>
              <div className="mt-3">
                <label  className="m-0">Answer</label>
                <CKEditor
                    editor={ ClassicEditor }
                    data={answer}
                    ProtectedTags = 'html|head|body'
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        answerfunc(data)
                    } }
                />
                {}
              </div>
            </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{submit(role,id)}}>
          Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
        <div className="p-3 w-100 bg-white">
          <div className="recr-table w-100 d-flex align-items-center justify-content-between">
            <h3>Help Center</h3>
            <Button variant="primary" onClick={handleinsert}>Add New</Button>
          </div>
          <table class="table table-hover help-center-teble w-100">
          <thead>
            <tr>
              <th scope="col">Question</th>
              <th scope="col">Answer</th>
              <th scope="col">Action</th>
              <th scope="col">Role</th>
            </tr>
          </thead>
          <tbody>

            {description&&description.length>0?description.map(val=>(
            <tr>
              <td className="help-question">
                  {val.question}
              </td>
              <td className="help-anwer">
                {parse(val.answer)}
              </td>
              <td>
                <Tooltip placement="topLeft" title={"Delete"}>
                  <button onClick={()=>{handledeleteShow(val.id)}} >
                    <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                  </button>
                </Tooltip>
                <Tooltip placement="topLeft" title={"Edit"}>
                <button onClick={()=>{edit(val.id)}}>
                <i class="fa fa-pencil text-success" aria-hidden="true"></i>
                </button>
                </Tooltip>
              </td>
              <td>
                {val.role ==1?
               <b>Employer</b>:
                <b>Candidate</b>
                }
              </td>
            </tr>
            )):<p className="m-0 p-2">No Q&A Availble</p>}
            
          </tbody>
        </table>
        </div>
      </div>
    </>
    :<Loader/>}
    </>
  );
}

export default HelpCenter;

// code from scoopsolutions