import React,{useState,useEffect} from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "../Manage-Terms-and-Conditions/ManageTermsAndConditions.css";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from "../../components/fullpageloader/fullPageLoader"
import { Tooltip, Button } from 'antd';





const ManageTermsAndConditions=(props)=> {

  
  useEffect(()=>{
    fetch("https://server.hirechoices.com/terms/get")
    .then(res => res.json())
    .then((response) => {
      setdescription(response.job[0].terms)
    })
  },[])




  const[description,setdescription]=useState("NO Data Available")
  const [loaders,setloaders]=useState(false)

  const submit=()=>{
    setloaders(true)
    const update = {terms:description}
    fetch("https://server.hirechoices.com/terms/update", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) => {
      window.location="/managetermsandconditions"
 setTimeout(
  () => this.setState(setloaders(false)), 
  3000
);
    })
  }

  return (
    <>
{!loaders ?
    <>
    <Navbar/>
    <Sidebar/>
    <div className="dashboard-parent all-main responsive-tal">
      <div className="d-flex align-items-start">
        <div className="">
          <section className="terms-parent">
            <section className="terms-parent-2">
              <h1>Current Terms and Conditions</h1>
              {/* <div className="text"> */}
              <CKEditor
                    editor={ ClassicEditor }
                    data={description}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setdescription(data)
                    } }
                />
              {/* </div> */}
              <div className="update-btn-parent">
                <Button variant="primary" onClick={()=>{submit()}}>Update</Button>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
    </>:<Loader/>}
</>
  );
}

export default ManageTermsAndConditions;

// code from scoopsolutions