import React, { useState,useEffect } from "react";
import "./contact.css";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import { Dropdown,Modal } from 'react-bootstrap';
import { Tooltip, Button } from 'antd';
import Loader from "../../components/fullpageloader/fullPageLoader"


function PackagesControlPanel() {
  const apphandleClose = () => setapproveshow(false);
  const [approveshow, setapproveshow] = useState(false);
  const [contactid,setcontactid] = useState()
  const apphandleShow = (val) => {
    setapproveshow(true)
    setcontactid(val)
  }
  const [loaders,setloaders]=useState(false)

  const[contact,setcontact] = useState([])

  useEffect(()=>{
    fetch("https://server.hirechoices.com/contact/get")
    .then(res => res.json())
    .then(response => setcontact(response.job))
  },[])


  const deletecontact = (e) =>{
    setloaders(true)
    const update = {id:e}
    fetch("https://server.hirechoices.com/contcat/delete", {
    method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify(update)
  })
  .then((res)=>{return res.json()})
  .then(response=>{
    window.location="/contact"
    setTimeout(
      () => this.setState(setloaders(false)), 
      3000
    );
  })
}


  return (
    <>
     {!loaders ?
    <>
      <Modal show={approveshow} onHide={apphandleClose}>
        <Modal.Body>
        {contact&&contact.filter(val => val.id == contactid).map(val => (
          <>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                First Name
              </label>
              <input type="text" readOnly defaultValue={val.first_name} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Last Name
              </label>
              <input type="text" readOnly defaultValue={val.last_name} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Company
              </label>
              <input type="text" readOnly defaultValue={val.company} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Contact Number
              </label>
              <input type="text" readOnly defaultValue={val.contact_number} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Email
              </label>
              <input type="text" readOnly defaultValue={val.email} className="form-control"/>
            </div>
            <div className="d-flex flex-column job-update-input-parent">
              <label>
                Subject
              </label>
              <textarea rows="10" type="text" readOnly defaultValue={val.subject} className="form-control"/>
            </div>
          </>
        ))}
        </Modal.Body>
       
      </Modal>
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
      <div className="d-flex align-items-start w-100">
        <div className="w-100 bg-white p-4">
        <div className="recr-table w-100 d-flex align-items-center justify-content-between">
            <h3>Contact Us</h3>
          </div>
          <div className="user-list">
            <table style={{display:"inline-table"}} class="table table-hover table-responsive">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <th scope="col">Company</th>
                <th scope="col">Contact Number</th>
                <th scope="col">Email</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
            {contact&&contact.length>0?contact.map(val => (
                <tr>
                <td>
                {val.first_name}
                </td>
                <td>
                {val.company}
                </td>
                <td>
                {val.contact_number}
                </td>
                <td>
                {val.email}
                </td>
                <td>
                    <Tooltip placement="topLeft" title={"View"}>
                    <button style={{background:"transparent",border:"none"}} onClick={()=>{apphandleShow(val.id)}}>
                      <i class="fa fa-eye mr-2 text-success" aria-hidden="true"></i>
                    </button>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={"Reply"}>
                    <button style={{background:"transparent",border:"none"}} onClick={()=>{
                      var mail = document.createElement("a");
                      mail.href = `mailto:${val.email}`;
                      mail.click();
                    }}>
                      <i class="fa fa-reply mr-2 text-warning" aria-hidden="true"></i>
                    </button>
                    </Tooltip>





                    <Tooltip placement="topLeft" title={"Delete"}>
                    <button style={{background:"transparent",border:"none"}} onClick={()=>{deletecontact(val.id)}}>
                      <i class="fa fa-trash mr-2 text-danger" aria-hidden="true"></i>
                    </button>
                    </Tooltip>
                </td>
                </tr>
              )):<p className="py-2">No Contact Us Available</p>}
            </tbody>
          </table>
              
          </div>
          </div>
        </div>
      </div>
    </>
     :<Loader/>}
     </>
  );
}

export default PackagesControlPanel;



// code from scoopsolutions