import React, { useEffect, useState } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "./profile.css";
import avatar from "../../assests/avatar.png";
import CV from "../../assests/cv.png"



function UserProfile() {
    const Token = localStorage.getItem("user")
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${Token}`);

    useEffect(() => {
        fetch('https://server.hirechoices.com/getuser', { method: 'GET', headers: myHeaders,})
        .then(res => res.json())
        .then(res => {
        persdata.push(res)
        setpersdata(persdata)
        })
        .catch(err=>{console.log("error",err)}
        
        )}
    ,[])

    useEffect(() => {
            fetch('https://server.hirechoices.com/getAllUser')
            .then(res => res.json())
            .then(response => setuserdata(response.user)
        )}
    ,[]);


    useEffect(() => {
        fetch('https://server.hirechoices.com/getAllResume')
            .then(res => res.json())
            .then(response => setgetresume(response.resume)
        )}
    ,[]);

    const [userdata, setuserdata] = useState([]);
    const [getresume, setgetresume] = useState([]);
    const [persdata, setpersdata] = useState([]);
    const [smuser,setsmuser] = useState(7)
    const [smresume,setsmresume] = useState(80)
    const [viewresume,setviewresume] = useState(false)

    return(
        <>
        <Navbar/>
        <Sidebar/>
        <div className="dashboard-parent all-main responsive-tal">
            <div className="profile-header">
                <div className="jobs-posted-heading-text221">
                    <h3>Profile</h3>
                </div>
                <div className="profile-header-absolute jobs-posted-heading-text2">
                    <div>
                        {
                            persdata&&persdata.length>0?persdata.map(val=>(
                            <>
                                <h3>{val.user.first_name}</h3>
                                <h6>{val.user.email}</h6>
                            </>
                            )):""
                        }
                    
                    </div>
                    <div className="profile-header-extra">
                        <h6 onClick={()=>{setviewresume(false)}}>All Users</h6>
                        <h6 onClick={()=>{setviewresume(true)}}>All Resumes</h6>
                    </div>
                </div>
            </div>
            {viewresume ?
                <div className="profile-main-body">
                    <div className="new-user-profile jobs-posted-heading-text2">
                        <h6>Resume</h6>
                        {getresume&&getresume.length>0?getresume.map((data) =>
                            <div onClick={()=>{setsmresume(data.id)}} className="profile-underuser">
                                <div className="avatar">
                                    <img src={CV} alt="" />
                                </div>
                                <div className="profile-underuser-name">
                                    <h5>{data.first_name}</h5>
                                    <h6>{data.email}</h6>
                                </div>
                            </div>
                        ):""}
                    </div>
                    <div className="new-user-profile21 jobs-posted-heading-text2">
                        <h6>Resume Information</h6>
                        {getresume&&getresume.length>0?getresume.filter(resume => resume.id == smresume).map((data) =>
                            <>
                                <div onClick={()=>{setsmresume(data.id)}} className="profile-underuser mt-4">
                                    <div className="avatar">
                                        <img src={CV} alt="" />
                                    </div>
                                    <div className="profile-underuser-name">
                                        <h5>{data.first_name}</h5>
                                        <h6>{data.email}</h6>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-4">
                                    <h6>Contact No:</h6>
                                    <h5 className="ml-1">{data.contact_number}</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <h6>Additional Contact No:</h6>
                                    <h5 className="ml-1">{data.add_contact_number}</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <h6>City:</h6>
                                    <h5 className="ml-1">{data.city}</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <h6>State:</h6>
                                    <h5 className="ml-1">{data.state}</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <h6>zip code:</h6>
                                    <h5 className="ml-1">{data.zipcode}</h5>
                                </div>
                                <div className="d-flex flex-column">
                                    <h6 className="m-0">Employment Eligibility:</h6>
                                    <p className="ml-1 mb-0 ">{data.employment_eligibility}.</p>
                                </div>
                                <div className="d-flex flex-column mt-2">
                                    <h6 className="m-0">Professional Summary:</h6>
                                    <p className="ml-1 mb-0">{data.professional_summary}.</p>
                                </div>
                            </>
                        ):""}
                    </div>
                </div> :
                <div className="profile-main-body">
                <div className="new-user-profile jobs-posted-heading-text2">
                    <h6>Users</h6>
                    {userdata&&userdata.length>0?userdata.map((data) =>
                        <div onClick={()=>{setsmuser(data.id)}} className="profile-underuser">
                            <div className="avatar">
                                <img src={avatar} alt="" />
                            </div>
                            <div className="profile-underuser-name">
                                <h5>{data.first_name}</h5>
                                <h6>{data.email}</h6>
                            </div>
                        </div>
                    ):""}
                </div>
                <div className="new-user-profile2 jobs-posted-heading-text2">
                    <h6>User Information</h6>
                    {userdata&&userdata.length>0?userdata.filter(person => person.id == smuser).map((data) =>
                        <>
                            <div onClick={()=>{setsmuser(data.id)}} className="profile-underuser mt-4">
                                <div className="avatar">
                                    <img src={avatar} alt="" />
                                </div>
                                <div className="profile-underuser-name">
                                    <h5>{data.first_name}</h5>
                                    <h6>{data.email}</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <h6>Position:</h6>
                                <h5 className="ml-1">{data.position_title}</h5>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6>Phone:</h6>
                                <h5 className="ml-1">{data.phone}</h5>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6>City:</h6>
                                <h5 className="ml-1">{data.city}</h5>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6>State:</h6>
                                <h5 className="ml-1">{data.state}</h5>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6>Company Name:</h6>
                                <h5 className="ml-1">{data.company_name}</h5>
                            </div>
                        </>
                    ):""}
                </div>
                </div>
            }
        </div>
        </>
    )
}

export default UserProfile;



// code from scoopsolutions