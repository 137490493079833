import React,{useState,useEffect} from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "../Manage-Privacy-Policy/ManagePrivacyPolicycss.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from "../../components/fullpageloader/fullPageLoader"
import { Tooltip, Button } from 'antd';


function ManagePrivacyPolicy() {
  useEffect(()=>{
    fetch("https://server.hirechoices.com/privacy/get")
    .then(res => res.json())
    .then((response) => {
      setdescription(response.job[0].privacy)
    })
  },[])
  const [loaders,setloaders]=useState(false)


  const[description,setdescription]=useState("NO Data Available")


  const submit=()=>{



    setloaders(true)
    const update = {privacy:description}
    fetch("https://server.hirechoices.com/privacy/update", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) =>{
      window.location="/manageprivacypolicy"
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );

    })
  }



  return (
    <>
    {!loaders ?
    <>
   <Navbar/>
    <Sidebar/>
    <div className="dashboard-parent all-main responsive-tal">
      <div className="d-flex align-items-start">
        <div className="">
          <section className="terms-parent">
            <section className="terms-parent-2">
              <h1>Privacy Policy</h1>
              <CKEditor
                    editor={ ClassicEditor }
                    data={description}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setdescription(data)
                    } }
                />
              <div className="update-btn-parent">
                <Button variant="primary" onClick={()=>{submit()}}>Update</Button>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
    </>
    :<Loader/>}
    </>
  );
}

export default ManagePrivacyPolicy;

// code from scoopsolutions